import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'norwegianNumberFormat',
    standalone: true,
})
export class NorwegianNumberFormatPipe implements PipeTransform {

  transform(value: number | null, locale: string = 'no-NO', minimumFractionDigits: number = 2, maximumFractionDigits: number = 2): string {
    if (value === null) return '';

    return new Intl.NumberFormat(locale, {
      minimumFractionDigits,
      maximumFractionDigits,
    }).format(value);
  }

}
